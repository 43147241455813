/* eslint-disable react/no-unused-state */
import React, { useState } from "react"
import * as styles from "./PagesLayout.module.less"
import { mTimestampValue } from "./HelperComponents"
import dayjs from "dayjs"
import {
  Typography,
  OutlinedInput as Input,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Button
} from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Grid } from "@mui/material"
import { styled } from "@mui/system"

const TIMEZONES = [
  { key: "GMT", value: "GMT", label: "GMT" },
  { key: "LTZ", value: "LTZ", label: "Local Time" }
]

// const TIMEZONE_KEY_PAIRS = {
//   GMT: "GMT",
//   LTZ: "Local Time"
// }

// const columns = [
//   { field: "id", headerName: "ID", width: 150 },
//   {
//     headerName: "Info",
//     field: "info",
//     key: "info",
//     width: 150
//   },
//   {
//     headerName: "Unix Timestamp",
//     field: "unixTimestamp",
//     key: "unixTimestamp",
//     width: 150
//   },
//   {
//     headerName: "ISO Date Time",
//     field: "dateTime",
//     key: "dateTime",
//     width: 200
//   }
// ]

const initialDateValues = () => {
  const timestampValue = mTimestampValue()
  const [year, month, day] = [(new Date(timestampValue)).getFullYear(), 1 + (new Date(timestampValue)).getMonth(), (new Date(timestampValue)).getDate()]
  return {
    timestampValue,
    year,
    month,
    day,
    datesDataSource: [],
    type: "year",
    timeZone: "GMT"
  }
}

const getTimezoneMenu = () => {
  return TIMEZONES.map(item => (
    <MenuItem key={item.key} value={item.value} label={item.value}>
      {item.label}
    </MenuItem>
  ))
}

let globalTimezone

const getTimezone = () => {
  if (!globalTimezone) {
    ({ timeZone: globalTimezone } = new Intl.DateTimeFormat().resolvedOptions())
  }
  return globalTimezone
}

const handleShowStartAndEndDate = ({ year, month, day, type = "", timeZone = "LTZ" }) => {
  ((timeZone === "LTZ") && (timeZone = getTimezone()))
  const momentObj = dayjs(`${year}-${month}-${day}`, "YYYY-MM-DD", timeZone)
  const startDateTime = momentObj.startOf(type).toISOString()
  const endDateTime = momentObj.endOf(type).toISOString()
  const startTimestamp = momentObj.startOf(type).valueOf()
  const endTimestamp = momentObj.endOf(type).valueOf()
  const typeStr = type.slice(0, 1).toUpperCase() + type.slice(1)
  return [
    {
      id: "1",
      info: `Start Of ${typeStr}`,
      unixTimestamp: startTimestamp,
      dateTime: startDateTime
    },
    {
      id: "2",
      info: `End Of ${typeStr}`,
      unixTimestamp: endTimestamp,
      dateTime: endDateTime
    }
  ]
}

const startAndEndTypeUpdated = (e, dateData, updateDateData) => {
  const type = e.target.value
  const datesDataSource = handleShowStartAndEndDate({ ...dateData, type })
  updateDateData({ ...dateData, type, datesDataSource })
}

const updateOnChange = ({ key, value, updateDateData, dateData }) => {
  let tempValue = value
  if (!["timeType", "timeZone"].includes(key)) {
    tempValue = value
      .split("")
      .filter(n => !Number.isNaN(Number(n)))
      .join("")
  }
  updateDateData({ ...dateData, [key]: tempValue })
}

const CustomInput = styled(Input)({
  width: `100%`,
  margin: 0,
  height: `36px`,
  "&.Mui-focused fieldset": {
    borderColor: `#b7b7b7 !important`
  }
})

const CustomInputSelect = styled(Select)({
  width: `100%`,
  height: `36px`,
  margin: 0,
  "&.Mui-focused fieldset": {
    borderColor: "#b7b7b7 !important"
  }
})

const StartAndEndOfDateTimeStampFn = ({ classes }) => {
  const [dateData, updateDateData] = useState(initialDateValues())
  const { year, month, day, datesDataSource = [], type, timeZone } = dateData
  return (
    <div id={"startAndEndOfDateTimeStamp"}>
      <Grid container style={{ marginTop: "50px" }}>
        <Grid item>
          <Typography variant={`h2`}
                      style={{
                        // color: "#4d3267",
                        fontSize: "24px",
                        fontWeight: "bold",
                        fontFamily: "Karla, sans-serif"
                      }}
          >
            Unix dates for the start and end of the year/month/week/day
          </Typography>
        </Grid>
      </Grid>
      <Grid container gutter={16}>
        <Grid item xs={12} sm={6} md={6} xl={3}>
          <ToggleButtonGroup value={type} size={"small"} color="primary" exclusive
                             onChange={(e) => startAndEndTypeUpdated(e, dateData, updateDateData)}>
            <ToggleButton value="year">Year</ToggleButton>
            <ToggleButton value="month">Month</ToggleButton>
            <ToggleButton value="week">Week</ToggleButton>
            <ToggleButton value="day">Day</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "16px" }} gutter={3}>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>Year</p>
          <span>
              <CustomInput
                aria-label={"year"}
                value={year}
                style={{
                  fontSize: "14px",
                  width: "60px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none"
                  // border: "1px solid #c7417b",
                }}
                onChange={e => {
                  const { value } = e.target
                  updateOnChange({ key: "year", value, updateDateData, dateData })
                }}
              />
            </span>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>-</p>
          <span style={{ margin: "2px", fontWeight: "bold" }}>-</span>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>Mon</p>
          <span>
              <CustomInput
                aria-label={"month"}
                value={month}
                style={{
                  fontSize: "14px",
                  width: "45px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none"
                  // border: "1px solid #c7417b",
                }}
                onChange={e => {
                  const { value } = e.target
                  updateOnChange({ key: "month", value, updateDateData, dateData })
                }}
              />
            </span>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>-</p>
          <span style={{ margin: "2px", fontWeight: "bold" }}>-</span>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>Day</p>
          <span>
              <CustomInput
                aria-label={"day"}
                value={day}
                style={{
                  fontSize: "14px",
                  width: "45px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none"
                  // border: "1px solid #c7417b",
                }}
                onChange={e => {
                  const { value } = e.target
                  updateOnChange({ key: "day", value, updateDateData, dateData })
                }}
              />
            </span>
        </Grid>

        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>&nbsp;</p>
          <span style={{ marginLeft: "10px" }}>
              <CustomInputSelect
                // label="timezone"
                aria-label={"timezone"}
                style={{
                  width: "120px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none",
                  marginBottom: "10px"
                  // border: '1px solid #c7417b',
                }}
                value={timeZone}
                onChange={e => {
                  const { value } = e.target
                  updateOnChange({ key: "timeZone", value, updateDateData, dateData })
                }}
              >
                {getTimezoneMenu()}
              </CustomInputSelect>
            </span>
        </Grid>
        <Grid item xs={12} sm={8} md={3} xl={3} style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>&nbsp;</p>
          <span>
              <Button
                className={`${styles.btnLayouts} ${styles.startAndEndDatesBtnLayout}`}
                type="primary"
                onClick={() => {
                  const tempDatesDataSource = handleShowStartAndEndDate(dateData)
                  updateDateData({ ...dateData, datesDataSource: tempDatesDataSource, timestamp: Date.now() })
                }}
              >
              Show ISO Dates & Timestamps
            </Button>
            </span>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "16px" }}>

        {datesDataSource && datesDataSource.length ?
          (
            <Box sx={{ height: 200, width: "60%" }}>
              <TableContainer style={{ borderRadius: 10, padding: 10 }} component={Paper}>
                <Table sx={{ minWidth: 650, border: 0.1, borderRadius: 10 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Info&nbsp;</TableCell>
                      <TableCell align="center">Unix Timestamp&nbsp;</TableCell>
                      <TableCell align="center">ISO Date Time&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {datesDataSource.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell align="center">{row.info}</TableCell>
                        <TableCell align="center">{row.unixTimestamp}</TableCell>
                        <TableCell align="center">{row.dateTime}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : null
        }
      </Grid>
    </div>
  )
}

export default StartAndEndOfDateTimeStampFn
