/* eslint-disable react/no-unused-state */
import React, { useState } from "react"
import * as styles from "./PagesLayout.module.less"
import { mTimestampValue } from "./HelperComponents"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { Button, OutlinedInput as Input, MenuItem, Select, Grid } from "@mui/material"
import { styled } from "@mui/system"

dayjs.extend(localizedFormat)

const utc = require("dayjs/plugin/utc")
const timezone = require("dayjs/plugin/timezone") // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)


const TIMEZONES = [
  { key: "GMT", value: "GMT", label: "GMT" },
  { key: "LTZ", value: "LTZ", label: "Local Time" }
]

// const TIMEZONE_KEY_PAIRS = {
//   GMT: "GMT",
//   LTZ: "Local Time"
// }

const updateOnChange = ({ key, value, updateTimestampData, timestampData }) => {
  let tempValue = value
  if (!["timeType", "timeZone"].includes(key)) {
    tempValue = value
      .split("")
      .filter(n => !Number.isNaN(Number(n)))
      .join("")
  }
  updateTimestampData({ ...timestampData, [key]: tempValue })
}

const getTimezoneMenu = () => {
  return TIMEZONES.map(item => (
    <MenuItem key={item.value} value={item.value} label={item.value}>
      {item.label}
    </MenuItem>
  ))
}

const getTimeMenu = () => {
  return ["AM", "PM"].map(item => (
    <MenuItem key={item} value={item} label={item}>
      {item}
    </MenuItem>
  ))
}

const CustomInput = styled(Input)({
  width: `100%`,
  margin: 0,
  height: `36px`,
  "&.Mui-focused fieldset": {
    borderColor: `#b7b7b7 !important`
  }
})

const CustomInputSelect = styled(Select)({
  width: `100%`,
  height: `36px`,
  margin: 0,
  "&.Mui-focused fieldset": {
    borderColor: "#b7b7b7 !important"
  }
})

const handleDateToTimestampConversion = (timestampValue) => {
  let units = "milliseconds"
  if (timestampValue && String(timestampValue).length > 14) {
    timestampValue /= 1000
    units = "microseconds (1/1,000,000 second)"
  } else if (timestampValue && String(timestampValue).length <= 11) {
    timestampValue *= 1000
    units = "seconds"
  }
  timestampValue *= 1
  const timeZone = getTimezone()
  const GMTTimeStr = dayjs(timestampValue)
    .tz("GMT")
    .format("dddd, MMM DD, YYYY hh:mm:ss.SSS A")
  const currentTimeStr = dayjs(timestampValue)
    .tz(timeZone)
    .format("dddd, MMM DD, YYYY hh:mm:ss.SSS A")
  const [year, month, day, hour, minutes, seconds, timeType] = (dayjs(timestampValue).tz(timeZone).format("YYYY$MM$DD$hh$mm$ss$A")).split("$")
  const finalTimeZone = "LTZ"
  const isoString = dayjs(timestampValue).toISOString()
  return {
    GMTTimeStr,
    currentTimeStr,
    units,
    displayTtoD: true,
    year,
    month,
    day,
    hour,
    minutes,
    seconds,
    timeType,
    timeZone: finalTimeZone,
    isoString
  }
}

const handleDateToTimestampConversionSubmit = (timestampData, updateTimestampData) => {
  let { year, month, day, minutes, seconds, timeType, hour, timeZone } = timestampData
  if (timeType === "PM") {
    hour = Number(hour) + 12
  }
  if (hour >= 24) {
    hour = "00"
  }

  ((timeZone === "LTZ") && (timeZone = getTimezone()))

  const timeStr = `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
  const unixTimestamp = dayjs.tz(timeStr, timeZone).valueOf()
  updateTimestampData({ ...timestampData, unixTimestamp })
}

let globalTimezone

const getTimezone = () => {
  if (!globalTimezone) {
    ({ timeZone: globalTimezone } = new Intl.DateTimeFormat().resolvedOptions())
  }
  return globalTimezone
}


const HumanReadableToTimestampView = ({ classes }) => {
  const [timestampData, updateTimestampData] = useState(handleDateToTimestampConversion(mTimestampValue()))
  const { year, month, day, hour, minutes, seconds, timeType, timeZone, unixTimestamp = "", isoString } = timestampData

  return (
    <div id={"humanReadableToTimestampView"}>
      <Grid container style={{ marginTop: "50px" }}>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>Year</p>
          <span>
              <CustomInput
                // label="year"
                aria-label={"year"}
                style={{
                  fontSize: "14px",
                  width: "60px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none"
                  // border: "1px solid #c7417b",
                }}
                value={year}
                onChange={e => {
                  const { value } = e.target
                  updateOnChange({ key: "year", value, updateTimestampData, timestampData })
                }}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    handleDateToTimestampConversionSubmit(timestampData, updateTimestampData)
                  }
                }}
              />
            </span>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>-</p>
          <span style={{ margin: "2px", fontWeight: "bold" }}>-</span>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>Mon</p>
          <span>
              <CustomInput
                // label="month"
                aria-label={"month"}
                style={{
                  fontSize: "14px",
                  width: "45px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none"
                  // border: "1px solid #c7417b",
                }}
                value={month}
                onChange={e => {
                  const { value } = e.target
                  updateOnChange({ key: "month", value, updateTimestampData, timestampData })
                }}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    handleDateToTimestampConversionSubmit(timestampData, updateTimestampData)
                  }
                }}
              />
            </span>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>-</p>
          <span style={{ margin: "2px", fontWeight: "bold" }}>-</span>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>Day</p>
          <span>
              <CustomInput
                // label="day"
                aria-label={"day"}
                style={{
                  fontSize: "14px",
                  width: "45px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none"
                  // border: "1px solid #c7417b",
                }}
                value={day}
                onChange={e => {
                  const { value } = e.target
                  updateOnChange({ key: "day", value, updateTimestampData, timestampData })
                }}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    handleDateToTimestampConversionSubmit(timestampData, updateTimestampData)
                  }
                }}
              />
            </span>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>&nbsp;</p>
          <span style={{ margin: "3px", fontWeight: "bold" }}>&nbsp;</span>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>Hr</p>
          <span>
              <CustomInput
                // label="hour"
                aria-label={"hour"}
                style={{
                  fontSize: "14px",
                  width: "45px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none"
                  // border: "1px solid #c7417b",
                }}
                value={hour}
                onChange={e => {
                  const { value } = e.target
                  updateOnChange({ key: "hour", value, updateTimestampData, timestampData })
                }}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    handleDateToTimestampConversionSubmit(timestampData, updateTimestampData)
                  }
                }}
              />
            </span>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>:</p>
          <span style={{ margin: "2px", fontWeight: "bold" }}>:</span>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>Min</p>
          <span>
              <CustomInput
                // label="minutes"
                aria-label={"minutes"}
                style={{
                  fontSize: "14px",
                  width: "45px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none"
                  // border: "1px solid #c7417b",
                }}
                value={minutes}
                onChange={e => {
                  const { value } = e.target
                  updateOnChange({ key: "minutes", value, updateTimestampData, timestampData })
                }}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    handleDateToTimestampConversionSubmit(timestampData, updateTimestampData)
                  }
                }}
              />
            </span>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>:</p>
          <span style={{ margin: "2px", fontWeight: "bold" }}>:</span>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>Sec</p>
          <span>
              <CustomInput
                // label="seconds"
                aria-label={"seconds"}
                style={{
                  fontSize: "14px",
                  width: "45px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none"
                  // border: "1px solid #c7417b",
                }}
                value={seconds}
                onChange={e => {
                  const { value } = e.target
                  updateOnChange({ key: "seconds", value, updateTimestampData, timestampData })
                }}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    handleDateToTimestampConversionSubmit(timestampData, updateTimestampData)
                  }
                }}
              />
            </span>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>&nbsp;</p>
          <span style={{ margin: "1px", fontWeight: "bold" }}>&nbsp;</span>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>&nbsp;</p>
          <span>
              <CustomInputSelect
                // label="am/pm"
                aria-label={"am/pm"}
                style={{
                  width: "75px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none",
                  marginBottom: "10px"
                  // border: '1px solid #c7417b',
                }}
                value={timeType}
                onChange={e => {
                  const { value } = e.target
                  updateOnChange({ key: "timeType", value, updateTimestampData, timestampData })
                }}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    handleDateToTimestampConversionSubmit(timestampData, updateTimestampData)
                  }
                }}
              >
                {getTimeMenu()}
              </CustomInputSelect>
            </span>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>&nbsp;</p>
          <span style={{ margin: "1px", fontWeight: "bold" }}>&nbsp;</span>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <p style={{ margin: 0 }}>&nbsp;</p>
          <span>
              <CustomInputSelect
                // label="timezone"
                aria-label={"timezone"}
                style={{
                  width: "120px",
                  marginLeft: 0,
                  borderRadius: "3px",
                  outline: 0,
                  boxShadow: "none",
                  marginBottom: "10px"
                  // border: '1px solid #c7417b',
                }}
                value={timeZone}
                onChange={e => {
                  const { value } = e.target
                  updateOnChange({ key: "timeZone", value, updateTimestampData, timestampData })
                }}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    handleDateToTimestampConversionSubmit(timestampData, updateTimestampData)
                  }
                }}
              >
                {getTimezoneMenu()}
              </CustomInputSelect>
            </span>
        </Grid>
        <Grid item xs={12} sm={7} md={5} xl={4}>
          <p style={{ margin: 0 }}>&nbsp;</p>
          <Button
            type="primary"
            className={`${styles.btnLayouts} ${styles.startAndEndDatesBtnLayout}`}
            onClick={() => handleDateToTimestampConversionSubmit(timestampData, updateTimestampData)}
          >
            Human-Readable To Timestamp
          </Button>
        </Grid>
      </Grid>
      {unixTimestamp && (
        <div style={{ marginTop: "10px" }}>
          <Grid container style={{ fontSize: "16px" }}>
            <Grid item md={4}>
              <p>
                <b>Unix timestamp </b>
              </p>
            </Grid>
            <Grid item>
              <p>:</p>
            </Grid>
            <Grid item>
              <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                &nbsp;{unixTimestamp && Number(unixTimestamp) / 1000}
              </p>
            </Grid>
          </Grid>
          <Grid container style={{ fontSize: "16px" }}>
            <Grid item md={4}>
              <p>
                <b>Timestamp in milliseconds </b>
              </p>
            </Grid>
            <Grid item>
              <p>:</p>
            </Grid>
            <Grid item>
              <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                &nbsp;{unixTimestamp && Number(unixTimestamp)}
              </p>
            </Grid>
          </Grid>
          <Grid container style={{ fontSize: "16px" }}>
            <Grid item md={4}>
              <p>
                <b>Date and time (Local Time) </b>
              </p>
            </Grid>
            <Grid item>
              <p>:</p>
            </Grid>
            <Grid item>
              <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                &nbsp;{dayjs(unixTimestamp).format("LLLL")}
              </p>
            </Grid>
          </Grid>
          <Grid container style={{ fontSize: "16px" }}>
            <Grid item md={4}>
              <p>
                <b>ISO Format ( ISO-8601 ) </b>
              </p>
            </Grid>
            <Grid item>
              <p>:</p>
            </Grid>
            <Grid item>
              <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                &nbsp;{isoString}
              </p>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

export default HumanReadableToTimestampView
