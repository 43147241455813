/* eslint-disable react/no-unused-state */
import React, { useState } from "react"
import * as styles from "./PagesLayout.module.less"
import TimezonesFilter from "../components/TimezonesFilter"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { Button, Typography, OutlinedInput as Input, Grid, ToggleButtonGroup, ToggleButton } from "@mui/material"
import { styled } from "@mui/system"
import { mTimestampValue } from "./HelperComponents"

dayjs.extend(localizedFormat)

var utc = require("dayjs/plugin/utc")
var timezone = require("dayjs/plugin/timezone") // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

let globalTimezone

const getTimezone = () => {
  if (!globalTimezone) {
    ({ timeZone: globalTimezone } = new Intl.DateTimeFormat().resolvedOptions())
  }
  return globalTimezone
}

const onTimestampChange = (e, updateTimestamp) => {
  let timestamp = e.target.value
  updateTimestamp(timestamp)
}

const CustomInput = styled(Input)({
  width: `100%`,
  margin: 0,
  height: `36px`,
  "&.Mui-focused fieldset": {
    borderColor: `#b7b7b7 !important`
  }
})

const getValidDiffTimeStr = ({ diffTime, type, past }) => {
  const tempDiffTime = Math.abs(diffTime)
  let extraStr = ""
  if (Number(tempDiffTime) === 0) {
    return false
  } else if (Number(tempDiffTime) > 1) {
    extraStr = "s"
  }
  const preFix = past ? "" : "In "
  const postFix = past ? " Ago" : ""
  return `${preFix}${tempDiffTime} ${type}${extraStr}${postFix}`
}

const getRelativeTimeStr = (timestampValue) => {
  const tempTimestampValue = Math.round(dayjs(timestampValue).valueOf())
  const timeDiff = dayjs().valueOf() - tempTimestampValue
  const past = timeDiff >= 0
  const years = getValidDiffTimeStr({
    diffTime: dayjs().diff(dayjs(tempTimestampValue), "years"),
    type: "year",
    past
  })
  const months = getValidDiffTimeStr({
    diffTime: dayjs().diff(dayjs(tempTimestampValue), "months"),
    type: "month",
    past
  })
  const days = getValidDiffTimeStr({
    diffTime: dayjs().diff(dayjs(tempTimestampValue), "days"),
    type: "day",
    past
  })
  const hours = getValidDiffTimeStr({
    diffTime: dayjs().diff(dayjs(tempTimestampValue), "hours"),
    type: "hour",
    past
  })
  const minutes = getValidDiffTimeStr({
    diffTime: dayjs().diff(dayjs(tempTimestampValue), "minutes"),
    type: "minute",
    past
  })
  return years || months || days || hours || minutes || "A few seconds ago"
}


const handleTimestampToDateConversion = ({ timestampValue, displayTtoD, timestampFormat = "dddd, MMM DD, YYYY hh:mm:ss.SSS A" }) => {
  const isTimestamp = Number(timestampValue)
  let units = "milliseconds"
  if (isTimestamp && timestampValue && String(timestampValue).length > 14) {
    timestampValue /= 1000
    units = "microseconds (1/1,000,000 second)"
  } else if (isTimestamp && timestampValue && String(timestampValue).length <= 11) {
    timestampValue *= 1000
    units = "seconds"
  }
  isTimestamp && (timestampValue *= 1)
  const timeZone = getTimezone()
  const GMTTimeStr = dayjs(timestampValue)
    .tz("GMT")
    .format(timestampFormat)
  const currentTimeStr = dayjs(timestampValue)
    .tz(timeZone)
    .format(timestampFormat)
  const [year, month, day, hour, minutes, seconds, timeType] = (dayjs(timestampValue).utc().tz(timeZone).format("YYYY$MM$DD$hh$mm$ss$A")).split("$")
  const finalTimeZone = "LTZ"
  const diffTimeStr = (dayjs(timestampValue).isValid() && getRelativeTimeStr(timestampValue)) || "Invalid Date"
  const isoString = (dayjs(timestampValue).isValid() && dayjs(timestampValue).toISOString()) || "Invalid Date"
  return {
    timestampValue: dayjs(timestampValue).valueOf(),
    GMTTimeStr,
    currentTimeStr,
    units,
    displayTtoD: !!displayTtoD,
    year,
    month,
    day,
    hour,
    minutes,
    seconds,
    timeType,
    timeZone: finalTimeZone,
    diffTimeStr,
    isoString,
    isTimestamp
  }
}

const isSSR = typeof window === "undefined"

const selectedTimezoneFromLocalStorage = (!isSSR && localStorage.getItem("selected_timezone")) || getTimezone()
const hourTypeFromLocalStorage = (!isSSR && localStorage.getItem("hours_type")) || "12hr"

const TimestampToHumanReadableViewFn = ({ classes }) => {
  const [hourType, updateHourType] = useState(hourTypeFromLocalStorage)
  let timestampFormat = "dddd, MMM DD, YYYY hh:mm:ss.SSS A"
  if (hourType === "24hr") {
    timestampFormat = "dddd, MMM DD, YYYY HH:mm:ss.SSS"
  }
  const [timestampValue, updateTimestamp] = useState("")
  const [selectedTimezone, setTempTimezone] = useState(selectedTimezoneFromLocalStorage)
  const [timestampData, updateTimestampData] = useState(handleTimestampToDateConversion({
    timestampValue,
    timestampFormat
  }))
  const { GMTTimeStr, currentTimeStr, units, displayTtoD, diffTimeStr, isoString, isTimestamp, timestampValue: rawTimestampValue } = timestampData

  return (
    <div id={"timestampToHumanReadableConverter"} className={styles.timestampToHumanReadableConverterContainer}>
      <Grid container spacing={3} style={{ marginTop: "25px" }}>
        <Grid item>
          <Typography variant={`h2`}
                      style={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        fontFamily: "Karla, sans-serif"
                      }}
          >
            Convert Epoch time / Unix timestamp to date or ISO date
            vice versa
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={5} md={8} xl={8}>
          <CustomInput
            size={`small`}
            // className={classes.inputLayouts}
            placeholder={"1577817000500 Or Saturday, Jan 01, 2020 12:00:00.500 AM"}
            value={timestampValue}
            onChange={(e) => onTimestampChange(e, updateTimestamp)}
            onKeyPress={event => {
              if (event.key === "Enter") {
                updateTimestampData(handleTimestampToDateConversion({ timestampValue, displayTtoD: true }))
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={3}>
          <Button
            className={styles.btnLayouts}
            type="primary"
            onClick={() => {
              if (timestampValue) {
                updateTimestampData(handleTimestampToDateConversion({
                  timestampValue,
                  displayTtoD: true
                }))
              } else {
                let tempTimestampValue = mTimestampValue()
                updateTimestamp(tempTimestampValue)
                updateTimestampData(handleTimestampToDateConversion({
                  timestampValue: tempTimestampValue,
                  displayTtoD: true
                }))
              }
            }}
          >
            Convert
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item
              style={{ textAlign: "justify", fontSize: "13px", marginTop: "5px", marginLeft: "2px" }}
        >
          Supports Epoch timestamps in seconds, milliseconds, microseconds and
          nanoseconds Or Any valid date time string.
        </Grid>
      </Grid>
      <div style={{ display: displayTtoD && GMTTimeStr !== "Invalid date" ? "block" : "none" }}>
        <Grid container spacing={3}>
          <Grid item>
            <p>
              {isTimestamp ? (<>Assuming that given timestamp is
                in <b>{units}</b>:</>) : `Assuming that given input is a date-time string:`}
            </p>
          </Grid>
          <Grid className={styles.selectLayoutsForHrs} item>
            <ToggleButtonGroup value={hourType} size={"small"} color="primary" exclusive
                               onChange={(e) => {
                                 const value = e?.target?.value || "12hr"
                                 updateHourType(value)
                                 localStorage.setItem("hours_type", value)
                               }}>
              <ToggleButton value="12hr">12 Hrs</ToggleButton>
              <ToggleButton value="24hr">24 Hrs</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        <Grid container style={{ fontSize: "16px" }} spacing={3}>
          <Grid item md={4}>
            <p>
              <b>GMT </b>
            </p>
          </Grid>
          <Grid item>
            <p>&nbsp;:</p>
          </Grid>
          <Grid item>
            <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
              &nbsp;{dayjs(GMTTimeStr).format(timestampFormat)}
            </p>
          </Grid>
        </Grid>
        <Grid container style={{ fontSize: "16px" }} spacing={3}>
          <Grid item md={4}>
            <p>
              <b>Current Time Zone ( GMT {dayjs().tz(getTimezone()).format("Z")} )</b>
            </p>
          </Grid>
          <Grid item>
            <p>&nbsp;:</p>
          </Grid>
          <Grid item>
            <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
              &nbsp;{dayjs(currentTimeStr).format(timestampFormat)}
            </p>
          </Grid>
        </Grid>
        <Grid container style={{ fontSize: "16px", display: "flex", alignItems: "center" }} spacing={3}>
          <Grid item xs={12} sm={5} md={4} xl={4}>
            <TimezonesFilter
              initialSelectedTimezone={selectedTimezone}
              updateSelectedTimezone={(value) => {
                if (!isSSR) {
                  localStorage.setItem("selected_timezone", value)
                }
                setTempTimezone(value)
              }}
            />
          </Grid>
          <Grid item>
            <p>&nbsp;:</p>
          </Grid>
          <Grid item>
            <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
              &nbsp;{dayjs(isoString).utc().tz(selectedTimezone).format(timestampFormat)}
            </p>
          </Grid>
        </Grid>
        <Grid container style={{ fontSize: "16px" }} spacing={3}>
          <Grid item md={4}>
            <p>
              <b>Relative </b>
            </p>
          </Grid>
          <Grid item>
            <p>&nbsp;:</p>
          </Grid>
          <Grid item>
            <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
              &nbsp;{diffTimeStr}
            </p>
          </Grid>
        </Grid>
        <Grid container style={{ fontSize: "16px" }} spacing={3}>
          <Grid item md={4}>
            <p>
              <b>ISO Format ( ISO-8601 ) </b>
            </p>
          </Grid>
          <Grid item>
            <p>&nbsp;:</p>
          </Grid>
          <Grid item>
            <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
              &nbsp;{isoString}
            </p>
          </Grid>
        </Grid>
        {!isTimestamp ? (<Grid container style={{ fontSize: "16px" }} spacing={3}>
          <Grid item md={2}>
            <p>
              <b>Timestamp </b>
            </p>
          </Grid>
          <Grid item>
            <p>&nbsp;:</p>
          </Grid>
          <Grid item>
            <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
              &nbsp;{rawTimestampValue}
            </p>
          </Grid>
        </Grid>) : null}
      </div>
    </div>
  )
}

export default TimestampToHumanReadableViewFn
