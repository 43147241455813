import React from "react"
import Container from "../components/container"
import Seo from "../components/Seo"
import CustomHeader from "../components/customHeader"
import CustomTimer from "../components/Timer"
import TimestampToHumanReadableView from "../components/TimestampToHumanReadableView"
import HumanReadableToTimestampView from "../components/HumanReadableToTimestampView"
import StartAndEndOfDateTimeStamp from "../components/StartAndEndOfDateTimeStamp"
import * as styles from "../components/PagesLayout.module.less"
import UnixTimeDefView from "../components/UnixTimeDefView"
import { Link as GatsbyLink } from "gatsby"
import { Typography } from "@mui/material"
import { styled } from "@mui/system"
// import ProgrammingSnippetForEpochTimeView from "../components/ProgrammingSnippetForEpochTimeView"
// import ProgrammingConversionForTimestamp from "../components/ProgrammingConversionForTimestamp"
// import ProgrammingEpochToHumanReadableConversion from "../components/ProgrammingEpochToHumanReadableConversion"
// import InPageAds from "../components/InPageAds"

const Link = styled(props => <GatsbyLink {...props} />)({
  color: "#69c",
  textDecoration: "none",
  "&:hover": { color: "#8fc9ff" }
})

const ProgrammingSnippetForEpochTimeView = React.lazy(() =>
  import("../components/ProgrammingSnippetForEpochTimeView")
)

const ProgrammingConversionForTimestamp = React.lazy(() =>
  import("../components/ProgrammingConversionForTimestamp")
)

const ProgrammingEpochToHumanReadableConversion = React.lazy(() =>
  import("../components/ProgrammingEpochToHumanReadableConversion")
)

const IndexPage = () => {
  const isSSR = typeof window === "undefined"
  // console.log(isSSR, "-----------------------------isSSR-------------------------------")
  const currentYear = (new Date()).getFullYear()
  return (
    <Container>

      <CustomHeader title={`Unix / Epoch Timestamp Conversion Tools`}/>

      <CustomTimer/>

      {/*<InPageAds/>*/}

      <TimestampToHumanReadableView/>

      <HumanReadableToTimestampView/>

      {/*<InPageAds/>*/}

      <StartAndEndOfDateTimeStamp/>


      <UnixTimeDefView/>

      {!isSSR && (
        <React.Suspense fallback={<div/>}>
          <ProgrammingSnippetForEpochTimeView/>
        </React.Suspense>
      )}

      {!isSSR && (
        <React.Suspense fallback={<div/>}>
          <ProgrammingConversionForTimestamp/>
        </React.Suspense>
      )}

      {!isSSR && (
        <React.Suspense fallback={<div/>}>
          <ProgrammingEpochToHumanReadableConversion/>
        </React.Suspense>
      )}

      <br/>
      <br/>
      <Typography variant={`h2`}
                  style={{
                    // color: "#4d3267",
                    fontSize: "24px",
                    fontWeight: "bold",
                    fontFamily: "Karla, sans-serif"
                  }}
      >
        Online Unix Time Conversion Tool Usages
      </Typography>
      <Typography>The tool in this page will allow you do all the basic and advanced time conversions like unix time to
        iso
        date-time string, human readable date time string in UTC timezone and you current timezone. The online unix time
        conversion tool has feature to convert human readable time with timezone to milli-second and seconds <Link
          target={`blank`} to={`/timestamps-list`}>timestamps.</Link><br/>
        The Unix timestamp conversion with all the timezones will be available here in <Link target={`blank`}
                                                                                             to={`/timezones-converter`}>timezone
          converter tool</Link>
      </Typography>
      <br/>
      <Typography variant={`h5`}
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    fontFamily: "Karla, sans-serif"
                  }}
      >
        Why Unix timestamp is needed?
      </Typography>
      <Typography>
        Unix/Epoch timestamp gives a unique representation of the date-time around worldwide. It always represents the
        date-time at GMT (Greenwich Mean Time Zone) or <Link target={`blank`}
                                                             to={`https://en.wikipedia.org/wiki/Coordinated_Universal_Time`}>UTC</Link>.
        The different location times will be obtained from the time difference to the <Link target={`blank`}
                                                                                            to={`https://en.wikipedia.org/wiki/Greenwich_Mean_Time`}>GTM</Link>.
        <br/><br/>
        The other alternative for timestamps to use in programming is ISO-String, but even the <Link target={`blank`}
                                                                                                     to={`https://en.wikipedia.org/wiki/ISO_8601`}>ISO-string</Link> are
        generic for all the timezones. The conversion of the date string to a different timezone is not as simple as
        converting the timestamp.
        <br/><br/>
        For Example, If the timestamp value is 1577923200 then,<br/>
      </Typography>
      <ul>
        <li>At GMT, the time is Thursday, January 2nd 2020, 00:00:00 GMT+00:00</li>
        <li>At India/Kolkata, the time is Thursday, January 2nd 2020, 05:30:00 GMT+05:30</li>
        <li>At America/New_York, the time is Wednesday, January 1st 2020, 19:00:00 GMT-5:00</li>
      </ul>
      <Typography variant={`h5`}>More About Timestamp conversions</Typography>
      <Typography variant={`p`}>
        Timestamp conversion is a crucial aspect of working with date and time in computer systems. Whether you're a
        developer, data scientist, or just someone who needs to keep track of time, understanding the different
        timestamp formats and how to convert between them is important. In this blog post, we'll delve into the most
        commonly used timestamp formats: epoch time, Unix timestamp, and ISO time string, as well as the importance of
        timestamp converters.
        <br/><br/>
        Epoch time, also known as Unix time or POSIX time, is the number of seconds that have elapsed since January 1,
        1970. This format is often used in programming and scripting languages, as it is easy to work with and compare.
        Epoch time is represented as a simple integer and is commonly used in Unix-based systems, such as Linux and
        macOS. One of the key advantages of using epoch time is that it is a universal and consistent format, making it
        easy to compare timestamps across different systems.
        <br/><br/>
        A Unix timestamp, on the other hand, is the number of seconds that have elapsed since January 1, 1970, but
        represented in a specific format. This format is typically a 32-bit integer or 64-bit integer, depending on the
        system. The Unix timestamp format is widely used in programming languages such as PHP, Python, and JavaScript.
        One of the advantages of using Unix timestamps is that it is a compact format, which is useful for applications
        that need to store timestamps in a small amount of memory.
        <br/><br/>
        An ISO time string is a standardized format for representing date and time, following the ISO 8601 standard.
        This format is often used for exchanging date and time information between systems, as it is human-readable and
        easily parseable. ISO time strings are represented in the format of "YYYY-MM-DDTHH:MM:SS" and can include time
        zone information. One of the key advantages of using ISO time strings is that it is a standard format which is
        recognized internationally, making it easy to exchange date and time information between different countries and
        regions.
        <br/><br/>
        With all these different timestamp formats, it's essential to have a tool that can convert between them.
        Timestamp converters are readily available online and can be very useful when working with timestamps in
        different formats. These tools can convert between epoch time, Unix timestamp, and ISO time string formats,
        allowing you to work with the format that best suits your needs.
        <br/><br/>
        In conclusion, understanding the different timestamp formats and how to convert between them is crucial when
        working with date and time in computer systems. Epoch time, Unix timestamp, and ISO time string are the most
        commonly used formats and each have their own advantages. Having a timestamp converter tool on your website can
        also be a valuable resource for your users.
      </Typography>
      <div className={styles.quickLinksContainer}>
        {/*<Typography variant={`h5`}>Quick Link</Typography>*/}
        {/*<div style={{ fontSize: "14px", padding: "0 5px" }}>*/}
        {/*  <Typography>*/}
        {/*    <Link to={"/#timestampToHumanReadableConverter"}>Unix Converter</Link>*/}
        {/*  </Typography>*/}
        {/*  <Typography>*/}
        {/*    <Link to={"/#humanReadableToTimestampView"}>Datetime Converter</Link>*/}
        {/*  </Typography>*/}
        {/*  <Typography>*/}
        {/*    <Link to={"/#startAndEndOfDateTimeStamp"}>StartOrEnd of Date View</Link>*/}
        {/*  </Typography>*/}
        {/*  <Typography>*/}
        {/*    <Link to={"/#programmingSnippetForEpochTimeView"}>Unix time conversion snippets</Link>*/}
        {/*  </Typography>*/}
        {/*  <Typography>*/}
        {/*    <Link to={"/#programmingHumanReadableToEpochConversion"}>Date to Unix conversion snippets</Link>*/}
        {/*  </Typography>*/}
        {/*  <Typography>*/}
        {/*    <Link to={"/#programmingEpochToHumanReadableConversion"}>Epoch to Date Time conversion snippets</Link>*/}
        {/*  </Typography>*/}
        {/*</div>*/}
        {/*<br/>*/}
        <Typography variant={`h5`}>Tools</Typography>
        <div style={{ fontSize: "14px", padding: "0 5px" }}>
          <Typography>
            <Link type={"link"} color={"link"} to={"/"}>Home - Unix/Epoch converter</Link>
          </Typography>
          <Typography>
            <Link to={"/timezones-converter/"}>Timezones converter</Link>
          </Typography>
          <Typography>
            <Link to={"/date-difference-calculator/"}>Date-time difference calculator</Link>
          </Typography>
          <Typography>
            <Link to={"/hex-timestamp-converter/"}>Hex timestamp converter</Link>
          </Typography>
          <Typography>
            <Link to={"/sas-timestamp-converter/"}>SAS timestamp converter</Link>
          </Typography>
          <Typography>
            <Link to={"/timestamps-list/"}>timestamp-list</Link>
          </Typography>
          <Typography>
            <Link to={"/unix-time-clock/"}>Epoch clock</Link>
          </Typography>
        </div>
        <br/>
        <Typography variant={`h5`}>Date and Time</Typography>
        <div style={{ fontSize: "14px", padding: "0 5px" }}>
          <Typography>
            <Link to={"/day-numbers/"}>Day Numbers</Link>
          </Typography>
          <Typography>
            <Link to={`/days/${currentYear}`}>Days by year</Link>
          </Typography>
          <Typography>
            <Link to={"/week-numbers/"}>Week numbers</Link>
          </Typography>
          <Typography>
            <Link to={`/weeks/${currentYear}`}>Weeks by year</Link>
          </Typography>
          <Typography>
            <Link to={"/years/"}>Years/leap years</Link>
          </Typography>
        </div>
        <br/>
        <Typography variant={`h5`}>Trending Blogs</Typography>
        <div style={{ fontSize: "14px", padding: "0 5px" }}>
          <Typography>
            <Link to={"/blogs/javascript-timestamp-conversions"}>Javascript timestamp Conversions</Link>
          </Typography>
          <Typography>
            <Link to={"/blogs/nodejs-timestamp-conversions"}>NodeJs timestamp Conversions</Link>
          </Typography>
          <Typography>
            <Link to={"/blogs/python-timestamp-conversions"}>Python timestamp Conversions</Link>
          </Typography>
          <Typography>
            <Link to={"/blogs/oracle-timestamp-conversions"}>Oracle timestamp Conversions</Link>
          </Typography>
          <Typography>
            <Link to={"/blogs/java-timestamp-conversions"}>Java timestamp Conversions</Link>
          </Typography>
          <Typography>
            <Link to={"/blogs/golang-timestamp-conversions"}>Golang timestamp Conversions</Link>
          </Typography>
        </div>
        <br/>
        <Typography variant={`h5`}>Others</Typography>
        <div style={{ fontSize: "14px", padding: "0 5px" }}>
          <Typography>
            <Link to={"/comments/"}>Comments & questions</Link>
          </Typography>
        </div>
      </div>

    </Container>
  )
}

export default IndexPage

export const Head = () => {
  return (
    <Seo
      title="Epoch Converter - Unix Timestamp Converter"
      canonicalUrl={"https://www.epochconverter.io"}
    />
  )
}
