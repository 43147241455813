import React, { useState } from "react"
import * as styles from "./PagesLayout.module.less"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { Autocomplete, TextField, Box } from "@mui/material"

dayjs.extend(localizedFormat)

const utc = require("dayjs/plugin/utc")
const timezone = require("dayjs/plugin/timezone") // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)


const { timezonesList } = require("../components/TimezonesList")
const allTimezonesList = timezonesList

const TimezonesFilter = ({ updateSelectedTimezone, initialSelectedTimezone }) => {

  const [selectedTimezone, updateSelectedTimezoneInternal] = useState(initialSelectedTimezone)
  return (<Autocomplete
    id={"timezones-autocomplete"}
    className={styles.selectLayoutsHR}
    autoComplete
    disableClearable
    size={`small`}
    sx={{
      marginLeft: 0,
      borderRadius: "3px",
      outline: 0,
      boxShadow: "none",
      marginBottom: "20px",
      width: "100%",
      height: "36px"
      // border: '1px solid #c7417b',
    }}
    options={allTimezonesList}
    getOptionLabel={(option) => option}
    onChange={(event, value) => {
      updateSelectedTimezoneInternal(value)
      if (updateSelectedTimezone) {
        updateSelectedTimezone(value)
      }
    }}
    value={selectedTimezone}
    renderOption={(props, option) => (
      <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
        {option} ( GMT {dayjs().tz(option).format("Z")} )
      </Box>)}
    renderInput={(params) => <TextField size={`small`} sx={{
      marginLeft: 0,
      borderRadius: "3px",
      outline: 0,
      boxShadow: "none",
      marginBottom: "20px",
      width: "100%",
      height: "36px"
      // border: '1px solid #c7417b',
    }}  {...params} label="Filter by regions"/>}
  >
  </Autocomplete>)
}

export default TimezonesFilter
