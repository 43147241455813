/* eslint-disable react/no-unused-state */
import React, { useState } from "react"
import { CopyOutlined } from "@ant-design/icons"
import { CopyToClipboard } from "react-copy-to-clipboard"
import * as styles from "./PagesLayout.module.less"
import { notification } from "antd"
import { mTimestampValue } from "./HelperComponents"
import { Typography, Button, Grid } from "@mui/material"

const onClickChangeUnits = (milliSec, updateMilliSec) => {
  updateMilliSec(milliSec)
}

const textCopiedNotification = () => {
  notification.success({
    message: <p style={{ fontSize: 14 }}>Timestamp Copied</p>
  })
}

const TimestampValue = ({ milliSec }) => {
  return (<>{TimestampValueRaw({ milliSec })}</>)
}

const TimestampValueRaw = ({ milliSec }) => {
  const [timestamp, updateTimestamp] = useState(mTimestampValue())
  const timestampStr = !milliSec
    ? Math.round(timestamp / 1000)
    : Math.round(timestamp)
  setTimeout(() => {
    updateTimestamp(mTimestampValue())
  }, 1)
  return (timestampStr)
}

const ClipboardComponent = () => {
  // eslint-disable-next-line
  return (<div
    style={{
      fontWeight: "bold",
      display: "inline-block",
      borderLeft: "1px solid #b7b7b7",
      padding: "0 10px",
      fontSize: "18px",
      margin: "0 0 0 10px",
      position: "relative",
      transform: "translateY(-25%)"
    }}
  >
    <CopyOutlined
      style={{
        margin: "0 5px 0 5px",
        color: "#b7b7b7",
        fontSize: "18px",
        cursor: "pointer"
      }}
    />
  </div>)
}


const Timer = () => {
  const [milliSec, updateMilliSec] = useState(false)
  const showTimerBtnStr = !milliSec
    ? "Show In Milli-Seconds"
    : "Show In Seconds"

  return (
    <Grid container sx={{ marginTop: "25px" }} spacing={3}>
      <Grid item xs={12} sm={7} md={4} xl={4}>
        <div role="presentation" style={{ fontFamily: "Fira Code, monospace" }}
             onClick={() => textCopiedNotification()}>
          <CopyToClipboard text={TimestampValueRaw({ milliSec })}>
            <div
              style={{
                marginTop: "2px",
                background: "rgb(52, 53, 59)",
                borderRadius: "5px",
                padding: "0 3px 0 3px",
                textAlign: "center"
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontFamily: "Montserrat, sans-serif"
                }}
              >
                <Typography style={{ padding: 0, margin: 0, color: "#b7b7b7", fontSize: "36px" }}>
                  <TimestampValue milliSec={milliSec}/>
                </Typography>
              </div>
              <ClipboardComponent milliSec={milliSec}/>
            </div>
          </CopyToClipboard>
        </div>
      </Grid>
      <Grid item xs={12} sm={4} md={4} xl={4} style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>

        <Button
          type="primary"
          className={styles.btnLayouts}
          style={{
            marginTop: "2px"
          }}
          onClick={() => onClickChangeUnits(!milliSec, updateMilliSec)}
        >
          {showTimerBtnStr}
        </Button>
      </Grid>
    </Grid>
  )
}

export default Timer
