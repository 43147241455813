/* eslint-disable react/no-unused-state */
import React from "react"
import { Col, Row } from "antd"
import { Typography } from "@mui/material";

const UnixTimeDefView = () => {
  return (
    <div>
      <Row style={{ marginTop: "50px" }}>
        <Col>
          <Typography variant={`h2`}
            style={{
              // color: "#4d3267",
              fontSize: "24px",
              fontWeight: "bold",
              fontFamily: "Karla, sans-serif"
            }}
          >
            What is Unix/Epoch Timestamp ?
          </Typography>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12} md={12} xl={16}>
            <Typography style={{ fontSize: "15px" }}>
              The <b>Unix Epoch Timestamp</b> is simply a representation of time in seconds or milliseconds. It measures the time elapsed since <b>January 1st, 1970</b>, at UTC (or GMT) to any particular moment, expressed in seconds (or milliseconds), and is referred to as the timestamp of that instance. The timestamp exclusively represents the time in <b>UTC </b>. Some systems present the time as a 32-bit integer, which may lead to an issue on <b>January 19, 2038</b> (also known as Y2038). The efficient tool on this page will assist you in converting timestamps from seconds (<b>10-digit</b>), milliseconds (<b>13-digit</b>) and microseconds (<b>16-digit</b>) into human-readable DateTime.
            </Typography>
        </Col>
      </Row>
      <Row style={{ marginTop: "50px" }}>
        <Col>
          <Typography variant={`h2`}
            style={{
              // color: "#4d3267",
              fontSize: "24px",
              fontWeight: "bold",
              fontFamily: "Karla, sans-serif"
            }}
          >
            About ISO 8601 date and time
          </Typography>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12} md={12} xl={16}>
            <Typography style={{ fontSize: "15px" }}>
              ISO 8601 is a date and time notation format defined by ISO as an international standard.

              The date and time are connected by "T" and written. The time zone is expressed as "+09:00" as the difference time from UTC, and in the case of UTC, it is expressed as "Z".

              Separate seconds and milliseconds with a comma (,) or dot (.). DenCode omits milliseconds when milliseconds are 000.

              ISO 8601 comes in several formats.
            </Typography>
        </Col>
      </Row>
    </div>
  )
}

export default UnixTimeDefView
